
import "./index.css"
import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Space, Button, Input } from "antd";
import Loader from "../../Components/Loader";
import AppLayout from "../../Components/Layout";
import GaugeChart from "../../Components/Charts/GaugeChart";
import { useAccount, useContract, useSigner, useContractEvent, useContractWrite } from "wagmi";
import { abi } from "../../constants/abis";
import SearchSelect from "../../Components/SearchSelect";
import { QuestionCircleOutlined } from "@ant-design/icons";
// import Footer from "../../Components/Footer";



function OnChain() {


    const [score, setScore] = useState(600);
    const [TxCount, SetTxCount] = useState(0)
    const [TxVolume, SetTxVolume] = useState(0)
    const [OutstandingLoans, SetOutstandingLoans] = useState(0)
    const [TotalBalance, SetTotalBalance] = useState(0)
    const [LoanHistory, SetLoanHistory] = useState([])
    const [LoanHistoryTable, SetLoanHistoryTable] = useState([])
    const [wallet, setWallet] = useState("")
    const [requestID, setRequestID] = useState("")

    const [loader, setLoader] = useState(false)

    const walletAddress = useRef("");


    const { address, isConnected, isDisconnected } = useAccount()
    const contractAddress = "0xB9bF4715142483A82C855312C384F1dB431bbb8E"
    const provider = new ethers.providers.WebSocketProvider("wss://capable-thrilling-daylight.ethereum-sepolia.quiknode.pro/0578dcce030c131658f0e28d9e4574bc4ea3ae3f/")

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: contractAddress,
        abi: abi,
        functionName: 'requestScore',
        args: [wallet]
    })








    const getScore = async () => {

        if (wallet) {
            setLoader(true)
            console.log('wller', wallet);
            localStorage.setItem('wal', wallet)
            write()

        }

    }


    useEffect(() => {

        if (isConnected) {

            var reqId = '';
            // var walletAdd = 
            const contract = new ethers.Contract(contractAddress, abi, provider)
            contract.on("ChainlinkRequested", (requestId) => {
                // console.log(walletAddress.current);
                // setRequestID(requestId);
                reqId = requestId;

                console.log(requestId)

            })

            contract.on("RequestScore", (requestId, credit_Score, userAddress) => {

                console.log("Ayaaaaaaaaaaaa")
                console.log('token', reqId);
                console.log(localStorage.getItem('wal'))
                console.log(userAddress)
                console.log(requestId === reqId)
                console.log(localStorage.getItem('wal') === userAddress)
                if (requestId === reqId && localStorage.getItem('wal') === userAddress) {

                    const creditScore = ethers.utils.formatEther(credit_Score) * 10 ** 18
                    if (creditScore === "0.0") {
                        // open modal
                    }
                    else {
                        console.log(creditScore)
                        setScore(creditScore)
                    }
                    setLoader(false)
                }

                console.log(requestId)
                console.log(credit_Score)
                console.log(userAddress)
            })

        }
    }, [isConnected])



    return (
        <AppLayout setWallet={setWallet} wallet={wallet}>

            {loader && <div className="fixed bg-gray-400/80 w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                <Loader />
            </div>
            }

            <div className="flex  justify-center ">
                <Card hoverable>
                    <h2 className="text-center text-xl">
                        Credit Score
                    </h2>
                    <GaugeChart score={score} />
                    <div className="flex justify-center flex-col items-center">

                        {/* <span className="text-lg"><a title="Guide" href="https://drive.google.com/file/d/1Nskrj92uTK5DJ9IiIynJuMkFVB-ce5nx/view?usp=sharing" target="blank" className="flex items-center" >How to Use <QuestionCircleOutlined utlined style={{ margin: "3px 0px 0px 5px", fontSize: '1rem' }} /></a></span> */}

                        {!isConnected && <span>Connect wallet and select an address from the dropdown</span>}
                        <SearchSelect setWallet={setWallet} disabled={!isConnected} />
                        <Button disabled={!isConnected} onClick={getScore} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                            Request Score
                        </Button>

                    </div>


                    {/* <Input placeholder="0xabc..." disabled={!isConnected} value={wallet} onChange={(e) => { setWallet(e.target.value) }}></Input> */}

                </Card>
            </div>
            {/* <Footer /> */}

        </AppLayout >
    );
}

export default OnChain;
