import React, { useState } from 'react';
import { Button, Flex, Row, Col, Input, Card } from 'antd';
import { convertFROMUSD, convertInUSD } from '../utils/helper';
const ButtonWithTwoText = ({ onClick, text1, text2, label1, label2, value1, setFunc1, value2, setFunc2, amountWethToUSD, amountWSTethToUSD, isApprove, apprveFunc }) => {

  const [quoteDollar, setQuoteDollar] = useState('0.00');
  const [collateralDollar, setCollateralDollar] = useState('0.00');

  const handleChange1 = (e) => {
    setFunc1(e.target.value);
    const amount = convertInUSD(e.target.value, amountWSTethToUSD);
    setCollateralDollar(amount);

  }



  const handleChange2 = (e) => {
    setFunc2(e.target.value);
    const amount = convertInUSD(e.target.value, amountWethToUSD);
    setQuoteDollar(amount);
  }


  return (
    <>

      <Card hoverable>
        <div className="flex justify-between items-center">

          <h2 className="text-xl w-[70px]">
            WSTETH
          </h2>
          <Input placeholder={label1} value={value1} onChange={(e) => handleChange1(e)} className="w-[50%] ml-2" type="number" />
          <label style={{ marginLeft: '10px' }}>${collateralDollar}</label>
        </div>
        <div className="flex justify-between items-center mt-2">

          <h2 className="text-xl w-[70px]">
            WETH
          </h2>
          <Input placeholder={label2} value={value2} onChange={(e) => handleChange2(e)} className="w-[50%] ml-2" type="number" />
          <label style={{ marginLeft: '10px' }}>${quoteDollar}</label>
        </div>
        <div className="flex justify-center flex-col items-center">
          {isApprove &&
            <Button onClick={apprveFunc} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg mt-4">
              Approve
            </Button>}

          <Button onClick={onClick} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg mt-4">
            {text1}
          </Button>

          {/* <div className="w-full p-2 text-center card-gradient">
            <p>Current User's WETH Borrowed,</p>
            <p>then Lent on Aave</p>
            <p>Borrower To Borrow Amount</p>
            <div className="flex justify-center items-center">

              <label>WETH:</label>
              <span className="ml-2">0.00</span>
            </div>
            <div className="flex justify-center items-center">
              <label>US:</label>
              <span className="ml-2">$0.00</span>
            </div>
          </div> */}
        </div>
      </Card>





      {/* <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
          <Button onClick={onClick} type="primary">
            <span style={{ fontSize: '20px' }}>{text1}</span>{'  '}
            <span style={{ fontSize: '12px' }}>{text2}</span>

          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>WSTETH: </label>
          <Input
            placeholder={label1}
            value={value1}
            onChange={(e) => handleChange1(e)}
            style={{ width: '180px' }}
          />
          <label style={{ marginLeft: '10px' }}>${collateralDollar}</label>
        </Col>

        <Col>
          <label>WETH: </label>
          <Input
            placeholder={label2}
            value={value2}
            onChange={(e) => handleChange2(e)}
            style={{ width: '180px' }}
          />
          <label style={{ marginLeft: '10px' }}>${quoteDollar}</label>
        </Col>
      </Row> */}
    </>
  )
};
export default ButtonWithTwoText;