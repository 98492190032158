import React, { useState } from 'react';
import { Button, Flex, Row, Col, Input, Card } from 'antd';
import { convertFROMUSD, convertInUSD } from '../utils/helper';
const ButtonWithText = ({ onClick, text1, text2, label, value, setFunc, crypto, amountInUSD,isApprove,approveFunc }) => {
  const [quoteDollar, setQuoteDollar] = useState('0.00');

  const handleChange = (e) => {
    setFunc(e.target.value);
    if (crypto) {
      const amount = convertInUSD(e.target.value, amountInUSD);
      setQuoteDollar(amount);
    }
  }

  return (
    <>
      <Card hoverable>
        <div className="flex justify-between items-center">

          <h2 className="text-xl">
            {crypto? crypto : "minutes"}
          </h2>
          <Input placeholder={label} value={value} onChange={(e) => handleChange(e)} className="w-[80%] ml-2" type="number" />
          {
            crypto && <label style={{ marginLeft: '10px' }}>${quoteDollar}</label>
          }
        </div>
        <div className="flex justify-center flex-col items-center">

{isApprove &&
        <Button onClick={approveFunc} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg mt-4">
            Approve
          </Button>
}
          <Button onClick={onClick} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg mt-4">
            {text1}
          </Button>


        </div>
      </Card>



  
    </>
  );
};
export default ButtonWithText;