
import "./index.css"
import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Space, Button, Typography, Input, Select } from "antd";
import Loader from "../../Components/Loader";
import AppLayout from "../../Components/Layout";
import GaugeChart from "../../Components/Charts/GaugeChart";
import { useAccount, useContract, useSigner, useContractEvent, useContractWrite } from "wagmi";
import { abi } from "../../constants/abis";
import SearchSelect from "../../Components/SearchSelect";
import { QuestionCircleOutlined } from "@ant-design/icons";
import TermsAndTimer from "../../Components/TermsAndTimer";
import { Option } from "antd/es/mentions";
// import Footer from "../../Components/Footer";
const { Text } = Typography;


function Admin() {


    const [score, setScore] = useState(600);
    const [TxCount, SetTxCount] = useState(0)
    const [TxVolume, SetTxVolume] = useState(0)
    const [OutstandingLoans, SetOutstandingLoans] = useState(0)
    const [TotalBalance, SetTotalBalance] = useState(0)
    const [LoanHistory, SetLoanHistory] = useState([])
    const [LoanHistoryTable, SetLoanHistoryTable] = useState([])
    const [wallet, setWallet] = useState("")
    const [requestID, setRequestID] = useState("")
    const [showWEthAddress, setShowWEthAddress] = useState(false)
    const [showWSTETHAddress, setShowWSTETHAddress] = useState(false)
    const [poolAddress, setPoolAddress] = useState('');
    const [loader, setLoader] = useState(false)

    const walletAddress = useRef("");


    const { address, isConnected, isDisconnected } = useAccount()
    const contractAddress = "0xB9bF4715142483A82C855312C384F1dB431bbb8E"
    const provider = new ethers.providers.WebSocketProvider("wss://capable-thrilling-daylight.ethereum-sepolia.quiknode.pro/0578dcce030c131658f0e28d9e4574bc4ea3ae3f/")

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: contractAddress,
        abi: abi,
        functionName: 'requestScore',
        args: [wallet]
    })








    const getScore = async () => {

        if (wallet) {
            setLoader(true)
            console.log('wller', wallet);
            localStorage.setItem('wal', wallet)
            write()

        }

    }


    useEffect(() => {

        if (isConnected) {

            var reqId = '';
            // var walletAdd = 
            const contract = new ethers.Contract(contractAddress, abi, provider)
            contract.on("ChainlinkRequested", (requestId) => {
                // console.log(walletAddress.current);
                // setRequestID(requestId);
                reqId = requestId;

                console.log(requestId)

            })

            contract.on("RequestScore", (requestId, credit_Score, userAddress) => {

                console.log("Ayaaaaaaaaaaaa")
                console.log('token', reqId);
                console.log(localStorage.getItem('wal'))
                console.log(userAddress)
                console.log(requestId === reqId)
                console.log(localStorage.getItem('wal') === userAddress)
                if (requestId === reqId && localStorage.getItem('wal') === userAddress) {

                    const creditScore = ethers.utils.formatEther(credit_Score) * 10 ** 18
                    if (creditScore === "0.0") {
                        // open modal
                    }
                    else {
                        console.log(creditScore)
                        setScore(creditScore)
                    }
                    setLoader(false)
                }

                console.log(requestId)
                console.log(credit_Score)
                console.log(userAddress)
            })

        }
    }, [isConnected]);




    return (
        <AppLayout showHeader={true} setWallet={setWallet} subPage={true} wallet={wallet}>

            {loader && <div className="fixed bg-gray-400/80 w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                <Loader />
            </div>
            }

            <div className="mt-20 min-h-[77vh] p-5">
                <Space
                    direction="vertical"
                    size={50}
                    style={{
                        display: 'flex',
                    }}
                >

                    <TermsAndTimer />

                    <div className="flex justify-around items-start">
                        <div className="w-[25%]">
                            <Card hoverable>
                                <div className="flex justify-between items-center">

                                    <h2 className="text-xl">
                                        Minutes
                                    </h2>
                                    {/* <Input className="w-[80%] ml-2" type="time" /> */}
                                    <Select className="w-[80%] ml-2">
                                        <Option>5 Min</Option>
                                        <Option>10 Min</Option>
                                        <Option>15 Min</Option>
                                    </Select>
                                </div>
                                <div className="flex justify-center flex-col items-center">

                                    <Button className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                        Begin Term Loan
                                    </Button>
                                </div>
                            </Card>
                        </div>
                        <div className="w-[25%]">
                            <Card hoverable>
                                <div className="flex justify-center flex-col items-center">
                                        <Text className="text-bold text-2xl">Lendvest APR</Text>
                                        <Text className="text-bold text-xl">Lender APR: 1.06%</Text>
                                        <Text className="text-bold text-xl">Borrower APR: 1.30%</Text>
                                    {/* <Button className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                        Begin Term Loan
                                    </Button> */}
                                </div>
                            </Card>
                        </div>
                        <div className="w-[25%]">
                            <Card hoverable>
                                <div className="flex justify-center flex-col items-center">

                                    <Button className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                        End Term Loan
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    </div>

                    <div className="flex justify-center items-stretch">
                        <div className="w-[78%] flex justify-center">
                            <div className="w-[30%]">
                                <Card hoverable>
                                    <div className="flex justify-center flex-col items-center">

                                        <Button onClick={() => { window.open('https://ajnafi.com/polygon/pools/0xC5850f7eCF8fd24Ab7fb28591bA9810dc6f2Ddda', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                            Go to Lendvest's Ajna Pool
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center items-center'>
                        <Card className='mt-5 w-[75%]' hoverable>
                            <h2 className='mb-2 text-center text-4xl w-full text-white'>Markets</h2>
                            <div className='flex justify-between items-center gap-4 flex-wrap mt-2'>
                                <div className="w-[45%]">
                                    <Card hoverable>
                                        <div className="flex justify-center flex-col items-center">

                                            <Button onClick={() => { window.open('https://app.aave.com/markets/?marketName=proto_mainnet', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                                Aave V2
                                            </Button>
                                            {poolAddress && (
                                                <Text style={{ display: 'block', marginTop: '8px' }}>
                                                    Pool Address: {poolAddress}
                                                </Text>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                                <div className="w-[45%]">
                                    <Card hoverable>
                                        <div className="flex justify-center flex-col items-center">

                                            <Button onClick={() => { window.open('https://app.aave.com/markets/?marketName=proto_mainnet_v3', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                                Aave V3
                                            </Button>
                                            {poolAddress && (
                                                <Text style={{ display: 'block', marginTop: '8px' }}>
                                                    Pool Address: {poolAddress}
                                                </Text>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                                <div className="w-[45%]">
                                    <Card hoverable>
                                        <div className="flex justify-center flex-col items-center">

                                            <Button onClick={() => { window.open('https://analytics.morpho.org/', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                                Morpho-Aave V2
                                            </Button>
                                            {poolAddress && (
                                                <Text style={{ display: 'block', marginTop: '8px' }}>
                                                    Pool Address: {poolAddress}
                                                </Text>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                                <div className="w-[45%]">
                                    <Card hoverable>
                                        <div className="flex justify-center flex-col items-center">

                                            <Button onClick={() => { window.open('https://analytics.morpho.org', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                                Morpho-Aave V3
                                            </Button>
                                            {poolAddress && (
                                                <Text style={{ display: 'block', marginTop: '8px' }}>
                                                    Pool Address: {poolAddress}
                                                </Text>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Card>
                    </div>



                    <div className="flex justify-center items-stretch">
                        <div className="w-[72%] flex justify-between">
                            <div className="w-[45%]">
                                <Card hoverable>
                                    <div className="flex justify-center flex-col items-center">

                                        <Button onClick={() => { setShowWEthAddress(true) }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                            Add WETH Address to Wallet
                                        </Button>
                                        {
                                            showWEthAddress ?
                                                <p>Quote Token Address: 0xcAAD1DE0D7bfDc07F4D526d864250e82751723a8</p>
                                                : null
                                        }
                                    </div>
                                </Card>
                            </div>
                            <div className="w-[45%]">
                                <Card hoverable>
                                    <div className="flex justify-center flex-col items-center">

                                        <Button onClick={() => setShowWSTETHAddress(true)} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                            Add WSTETH Address to Wallet
                                        </Button>
                                        {
                                            showWSTETHAddress ?
                                                <p>Collateral Address: 0x03b54A6e9a984069379fae1a4fC4dBAE93B3bCCD</p>
                                                :
                                                null
                                        }

                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>



                    <div className="w-full flex justify-center">
                        <div className="w-[80%] flex justify-between flex-wrap items-center gap-4">
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Current User Interest Owe in</div>
                                                <div className="mt-2 text-2xl">WETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >WETH In Lendvest's Contract</div>
                                                <div className="mt-2 text-2xl">WETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >WSTETH In Lendvest's Contract</div>
                                                <div className="mt-2 text-2xl">WSTETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Amount Lent on Aave</div>
                                                <div className="mt-2 text-2xl">WETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Cross Collateralization in Reserve</div>
                                                <div className="mt-2 text-2xl">WETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Current User's WETH Lent</div>
                                                <div className="mt-2 text-2xl">WETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Interest Earned By Current User</div>
                                                <div className="mt-2 text-2xl">WETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%]">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Current User's WSTETH Collateral</div>
                                                <div className="mt-2 text-2xl">WSTETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%] last-cards">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem] text-center" >Amount of collateral deposited by the collateral provider</div>
                                                <div className="mt-2 text-2xl">WSTETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                            <div className="w-[40%] last-cards">
                                <Card hoverable >
                                    <span>
                                        <div>
                                            <div className="flex justify-center items-center flex-col">
                                                <div className="text-[1.3rem]" >Cross Collateral WSTETH Used</div>
                                                <div className="mt-2 text-2xl">WSTETH 0.00</div>
                                                <div className="mt-2 text-2xl">US $0.00</div>
                                            </div>
                                        </div>
                                    </span>
                                </Card>
                            </div>
                        </div>

                    </div>
                </Space>

            </div>



        </AppLayout >
    );
}

export default Admin;
