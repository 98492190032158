import React from "react";


const TermsAndTimer = () => {
    return (
        <div className="w-full flex justify-end items-center fixed -ml-[51px] -mt-[75px]">
            <div className="">
                <div>
                    <label className="text-white text-lg font-bold">Term Status: </label>
                    <span className="text-white text-lg ms-2 font-bold">OFF</span>
                </div>
                <div>
                    <label className="text-white text-lg font-bold">Timer: </label>
                    <span className="text-white text-lg ms-2 font-bold">0:00:00</span>
                </div>
            </div>
        </div>
    )
};

export default TermsAndTimer;