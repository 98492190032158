import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { LendvestAjnaAddress, LendvestAjnaABI, WethAddress, WSTethAddress, WethABI, poolInfoAddress, poolInfoABI, PoolABI, PoolAddress } from "../../utils/index"
import { Button, Divider, Row, Col, Typography, Input, Space, Card } from 'antd';
import ButtonWithText from '../../Components/ButtonWithText'
import ButtonWithTwoText from '../../Components/ButtonWithTwoText'
import Timer from '../../Components/Timer'
import { calculateLendSubmission, convertFROMUSD, convertInUSD, convertToUSD, cryptoToID } from '../../utils/helper'
import AppLayout from '../../Components/Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate } from 'react-router-dom';
import TermsAndTimer from '../../Components/TermsAndTimer';
import axios from 'axios';
import { AUTOFILL_API, borrow_upsert, lend_upsert, LENDERS_API, total_borrow_ask, total_lend, UPDATES_API } from '../../utils/apis';
const { Text } = Typography;



function Home() {

  const location = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState(0)
  const [address, setAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [amountWethToUSD, setAmountWethToUSD] = useState('');
  const [amountWSTethToUSD, setAmountWWSTethToUSD] = useState('');

  const [poolAddress, setPoolAddress] = useState('');
  const [collateralAddress, setCollateralAddress] = useState('');
  const [quoteTokenAddress, setQuoteTokenAddress] = useState('');

  const [quoteTokenAmount, setQuoteTokenAmount] = useState('0');
  const [askForBorrowAmount, setAskForBorrowAmount] = useState('0');
  const [withdrawQuoteTokenAmount, setWithdrawQuoteTokenAmount] = useState('0');
  const [depositColletralAmount, setDepositColletralAmount] = useState('0');
  const [withdrawColletralAmount, setWithdrawColletralAmount] = useState('0');
  const [borrowAmount, setborrowAmount] = useState('0');
  const [borrowColletralAmount, setBorrowColletralAmount] = useState('0');
  const [repayColletralAmount, setRepayColletralAmount] = useState('0');
  const [repayQuoteAmount, setRepayQuoteAmount] = useState('0');

  const [borrowerAddressForAvoidLiquidation, setBorrowerAddressForAvoidLiquidation] = useState('');

  // Accounting states in usd
  const [totalAmountOfQuoteToken, settotalAmountOfQuoteToken] = useState('0.00');
  const [totalAmountOfCollateral, settotalAmountOfCollateral] = useState('0.00');
  const [totalAmountOfBorrowed, settotalAmountOfBorrowed] = useState('0.00');
  const [totalHelpingCollateral, settotalHelpingCollateral] = useState('0.00');
  const [totalAmountOnAave, settotalAmountOnAave] = useState('0.00');

  const [supplierToAmount, setsupplierToAmount] = useState('0.00');
  const [interestEarnedByUser, setinterestEarnedByUser] = useState('0.00');
  const [borrowerToCollateralAmount, setborrowerToCollateralAmount] = useState('0.00');
  const [borrowerToBorrowAmount, setborrowerToBorrowAmount] = useState('0.00');
  const [borrowerToAtoken, setborrowerToAtoken] = useState('0.00');
  const [collateralProviderToSuppliedAmount, setcollateralProviderToSuppliedAmount] = useState('0.00');
  const [collateralProviderToCrossColletralAmount, setcollateralProviderToCrossColletralAmount] = useState('0.00');
  const [balanceOfCollateralNotUsed, setbalanceOfCollateralNotUsed] = useState('0.00');
  const [InterestPaidForCollateral, setInterestPaidForCollateral] = useState('0.00');
  const [epochStatus, setepochStatus] = useState('No');

  // Server Utilization
  const [totalBorrowAsk, setTotalBorrowAsk] = useState('0.00');
  const [totalBorrowAskUSD, setTotalBorrowAskUSD] = useState('0.00');
  const [totalLend, setTotalLend] = useState('0.00');
  const [totalLendUSD, setTotalLendUSD] = useState('0.00');
  const [lenders, setLenders] = useState([])


  // Accounting states in actual values
  const [totalAmountOfQuoteTokenActual, settotalAmountOfQuoteTokenActual] = useState('0.000000');
  const [totalAmountOfCollateralActual, settotalAmountOfCollateralActual] = useState('0.00');
  const [totalAmountOfBorrowedActual, settotalAmountOfBorrowedActual] = useState('0.000000');
  const [totalHelpingCollateralActual, settotalHelpingCollateralActual] = useState('0.00');
  const [totalAmountOnAaveActual, settotalAmountOnAaveActual] = useState('0.000000');

  const [supplierToAmountActual, setsupplierToAmountActual] = useState('0.000000');
  const [interestEarnedByUserActual, setinterestEarnedByUserActual] = useState('0.000000');
  const [borrowerToCollateralAmountActual, setborrowerToCollateralAmountActual] = useState('0.000000');
  const [borrowerToBorrowAmountActual, setborrowerToBorrowAmountActual] = useState('0.00');
  const [borrowerToAtokenActual, setborrowerToAtokenActual] = useState('0.00');
  const [collateralProviderToSuppliedAmountActual, setcollateralProviderToSuppliedAmountActual] = useState('0.00');
  const [collateralProviderToCrossColletralAmountActual, setcollateralProviderToCrossColletralAmountActual] = useState('0.000000');
  const [balanceOfCollateralNotUsedActual, setbalanceOfCollateralNotUsedActual] = useState('0.00');
  const [InterestPaidForCollateralActual, setInterestPaidForCollateralActual] = useState('0.000000');


  // address for supplierToAmount

  const [supplierToAmountAddress, setsupplierToAmountAddress] = useState('');
  const [interestEarnedByUserAddress, setinterestEarnedByUserAddress] = useState('');
  const [borrowerToCollateralAmountAddress, setborrowerToCollateralAmountAddress] = useState('');
  const [borrowerToBorrowAmountAddress, setborrowerToBorrowAmountAddress] = useState('');
  const [borrowerToAtokenAddress, setborrowerToAtokenAddress] = useState('');
  const [collateralProviderToSuppliedAmountAddress, setcollateralProviderToSuppliedAmountAddress] = useState('');
  const [collateralProviderToCrossColletralAmountAddress, setcollateralProviderToCrossColletralAmountAddress] = useState('');
  const [balanceOfCollateralNotUsedAddress, setbalanceOfCollateralNotUsedAddress] = useState('');

  const [minutes, setMinutes] = useState(15);

  const [signer, setSigner] = useState();
  const [contract, setContract] = useState();
  const [WSTContract, setWSTContract] = useState();
  const [WethContract, setWethContract] = useState();
  const [poolInfoContract, setPoolInfoContract] = useState();
  const [poolContract, setPoolContract] = useState();


  const [duration, setDuration] = useState(0); // Set initial duration in seconds (5 minutes)
  const [deadline, setDeadline] = useState(0);
  const [isActive, setIsActive] = useState(false);



  // Pool States
  const [collateralPledge, setCollateralPledge] = useState(0); // Set initial duration in seconds (5 minutes)
  const [depositSize, setDepositSize] = useState(0);
  const [curentDebt, setCurentDebt] = useState(0);

  const handleStart = () => {
    setDuration(minutes * 60)
    setDeadline(Date.now() + 1000 * minutes * 60);
    setIsActive(true);
  };


  const notifySuccess = (message) => {
    toast.success(message);
  }

  const notifyError = (message) => {
    toast.error(message);
  }


  const handleReset = () => {
    setIsActive(false);
    // setDuration(5 * 60); // Reset to 5 minutes
  };


  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        // Request wallet connection
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const sig = provider.getSigner();
        const address = await sig.getAddress();
        const balance = await provider.getBalance(address);
        const contract = new ethers.Contract(LendvestAjnaAddress, LendvestAjnaABI, sig);
        const Wethcont = new ethers.Contract(WethAddress, WethABI, sig);
        const WStethcont = new ethers.Contract(WSTethAddress, WethABI, sig);
        const poolInfo = new ethers.Contract(poolInfoAddress, poolInfoABI, sig);
        const pool = new ethers.Contract(PoolAddress, PoolABI, sig);

        setContract(contract)
        setSigner(sig);
        setWethContract(Wethcont);
        setWSTContract(WStethcont);
        setPoolInfoContract(poolInfo)
        setPoolContract(pool);
        // Update state
        setAddress(address);
        setBalance(ethers.utils.formatEther(balance));
        console.log('Wallet connected successfully');
      } else {
        console.error('MetaMask is not installed. Please install MetaMask.');
      }
    } catch (error) {
      console.error('Error connecting to wallet:', error);
      console.error('Failed to connect to wallet');
    }
  };

  // Accounting Functions
  const getInterestPaidForCollateral = async () => {
    const amount = await contract.getInterestPaidByBorrowerToGetFullCollateral(ethers.utils.parseEther(borrowerToBorrowAmountActual));
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    setInterestPaidForCollateral(amount_usd);
    setInterestPaidForCollateralActual(ethers.utils.formatEther(amount));
  };

const gettotalAmountOfQuoteToken = async () => {
    console.log("getting totalAmountOfQuoteToken")
    const amount = await contract.totalAmountOfQuoteToken();
    // console.log(amount)
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    settotalAmountOfQuoteToken(amount_usd);
    settotalAmountOfQuoteTokenActual(ethers.utils.formatEther(amount));

  };


  const gettotalAmountOfCollateral = async () => {
    console.log("getting ttotalAmountOfCollateral")
    const amount = await contract.totalAmountOfCollateral();
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWSTethToUSD);
    settotalAmountOfCollateral(amount_usd)
    settotalAmountOfCollateralActual(ethers.utils.formatEther(amount))

  };


  const gettotalAmountOnAave = async () => {
    console.log("getting totalAmountOnAave")
    const amount = await contract.totalAmountOnAave();
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    settotalAmountOnAave(amount_usd)
    settotalAmountOnAaveActual(ethers.utils.formatEther(amount))

  };

  const gettotalAmountOfBorrowed = async () => {
    console.log("getting totalAmountOfBorrowed")
    const amount = await contract.totalAmountOfBorrowed();
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    settotalAmountOfBorrowed(amount_usd)
    settotalAmountOfBorrowedActual(ethers.utils.formatEther(amount))

  };


  // Internal Accounting Functions
  const getsupplierToAmount = async () => {
    console.log("getting supplierToAmount")
    const amount = await contract.supplierToAmount(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    setsupplierToAmount(amount_usd)
    setsupplierToAmountActual(ethers.utils.formatEther(amount))

  };


  const getinterestEarnedByUser = async () => {
    console.log("getting interestEarnedByUser")
    const amount = await contract.interestEarnedByUser(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    setinterestEarnedByUser(amount_usd)
    setinterestEarnedByUserActual(ethers.utils.formatEther(amount))

  };


  const getborrowerToCollateralAmount = async () => {
    console.log("getting borrowerToCollateralAmount")
    const amount = await contract.borrowerToCollateralAmount(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWSTethToUSD);
    setborrowerToCollateralAmountActual(ethers.utils.formatEther(amount))
  };

  const getborrowerToBorrowAmount = async () => {
    console.log("getting borrowerToBorrowAmount")
    const amount = await contract.borrowerToBorrowAmount(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWethToUSD);
    setborrowerToBorrowAmount(amount_usd)
    setborrowerToBorrowAmountActual(ethers.utils.formatEther(amount))

  };

  const getborrowerToAtoken = async () => {
    console.log("getting borrowerToAtoken")
    const amount = await contract.borrowerToAtoken(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWSTethToUSD);
    setborrowerToAtoken(amount_usd)
    setborrowerToAtokenActual(ethers.utils.formatEther(amount))

  };


  const getcollateralProviderToSuppliedAmount = async () => {
    console.log("getting collateralProviderToSuppliedAmount")
    const amount = await contract.collateralProviderToSuppliedAmount(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWSTethToUSD);
    setcollateralProviderToSuppliedAmount(amount_usd)
    setcollateralProviderToSuppliedAmountActual(ethers.utils.formatEther(amount))

  };


  const getcollateralProviderToCrossColletralAmount = async () => {
    console.log("getting collateralProviderToCrossColletralAmount")
    const amount = await contract.collateralProviderToCrossColletralAmount(address);
    const amount_usd = convertInUSD(ethers.utils.formatEther(amount), amountWSTethToUSD);
    setcollateralProviderToCrossColletralAmount(amount_usd);
    setcollateralProviderToCrossColletralAmountActual(ethers.utils.formatEther(amount));

  };

  const getEpochStauts = async () => {
    const es = await contract.epochStarted();
    es ? setepochStatus("Yes") : setepochStatus("No")

  };


  const getTotalBorrowAmountAsk = async () => {
    const totalBorrowAskAmount = await axios.get(total_borrow_ask);
    const totalBorrowAskAmountUSD = convertInUSD(totalBorrowAskAmount?.data?.total_borrow_ask, amountWethToUSD)
    setTotalBorrowAsk(totalBorrowAskAmount?.data?.total_borrow_ask)
    setTotalBorrowAskUSD(totalBorrowAskAmountUSD)

    const totalLendAmount = await axios.get(total_lend);
    const totalLendAmountUSD = convertInUSD(totalLendAmount?.data?.total_lend_amount, amountWethToUSD)


    setTotalLend(totalLendAmount?.data?.total_lend_amount)
    setTotalLendUSD(totalLendAmountUSD)

    const lendersData = await axios.get(LENDERS_API);
    console.log(">>>>", lendersData)
    setLenders(lendersData?.data)

  };


  const getCollateralPledge = async() =>{
    setCollateralPledge(ethers.utils.formatEther(await poolContract.pledgedCollateral()));
  }


  const getCurrentDebtOfPool = async() =>{
  const debt = await poolContract.debtInfo();
  console.log(debt);
  setCurentDebt(ethers.utils.formatEther(debt[0]));
  }

// total Lendings
  const getDepositSize = async() =>{
    setDepositSize(ethers.utils.formatEther(await  poolContract.depositSize()));
  }



  const getAllAcounting = () => {
    gettotalAmountOfQuoteToken()
    gettotalAmountOfCollateral()
    gettotalAmountOfBorrowed()
    gettotalAmountOnAave()
    getsupplierToAmount()
    getinterestEarnedByUser()
    getborrowerToCollateralAmount()
    getborrowerToBorrowAmount()
    getborrowerToAtoken()
    getcollateralProviderToSuppliedAmount()
    getcollateralProviderToCrossColletralAmount()
    getEpochStauts()
    getInterestPaidForCollateral()
    getTotalBorrowAmountAsk()
    getCollateralPledge()
    getCurrentDebtOfPool()
    getDepositSize()

  }


  const ApproveLendQuoteToken = async () => {
    try {
      const approvTx = await WethContract.approve(LendvestAjnaAddress, ethers.utils.parseEther(`${quoteTokenAmount}`))
      await approvTx.wait();
      console.log("Approve Tx Suucessful..", approvTx)
      notifySuccess("Approve Tx Successful.")
      getAllAcounting()

    } catch (error) {
      notifyError("Approve Tx Failed.")
      console.error("Approve Tx Failed..", error)
    }

  }

  const LendQuoteToken = async () => {

    const flag = true;

    try {
      const tx = await contract.lendQuoteToken(ethers.utils.parseEther(`${quoteTokenAmount}`), { gasLimit: 3000000 });
      await tx.wait();



      console.log("Lend Quote Token Tx Successful..", tx)
      notifySuccess("Lend Quote Token Tx Successful.")
    } catch (error) {
      notifyError("Lend Quote Tokens Tx Failed.")
      flag = false;
      console.log(error)
    }

    if (flag) {
      await axios.post(lend_upsert, {
        address: address,
        lend_amount: quoteTokenAmount
      })
    }

  }

  const test = () => {
    try {
      // const approvTx = await WethContract.approve(LendvestAjnaAddress, ethers.utils.parseEther(`${quoteTokenAmount}`))
      console.log(ethers.utils.parseEther(`${quoteTokenAmount}`))
      // await approvTx.wait();
      // console.log("Approve Tx Suucessful..", approvTx)
      notifySuccess("Approve Tx Successful.")
      // getAllAcounting()

    } catch (error) {
      notifyError("Approve Tx Failed.")
      console.error("Approve Tx Failed..", error)
    }

  }


  const AskForBorrow = async () => {
    try {

      if (address) {


        const bfr = ethers.utils.formatEther(await poolInfoContract.borrowFeeRate(await contract.pool()))
        const dfr = ethers.utils.formatEther(await poolInfoContract.depositFeeRate(await contract.pool()))
        console.log("dffrr",dfr)
        const utilization_rate = 0.96;
        const depositCollateral = (((1 / 1.173) * Number(askForBorrowAmount)) + ((1 / 1.173) * Number(askForBorrowAmount)) * 0.1).toFixed(12)
        // const depositQuoteToken = (((Number(askForBorrowAmount) + Number(askForBorrowAmount) * 0.0005) / 0.96) / (1 + 0.00000008383561643836)).toFixed(12)
        const depositQuoteToken = (Number(askForBorrowAmount) + Number(askForBorrowAmount) * Number(dfr) +  (Number(askForBorrowAmount) / utilization_rate) - Number(askForBorrowAmount) ).toFixed(12)
        // const depositQuoteToken = (Number(askForBorrowAmount) + Number(askForBorrowAmount) * Number(dfr) ).toFixed(18)

        
        const repaycollateral = (Number(depositCollateral) - Number(depositCollateral) * Number(bfr)).toFixed(12)
        const withdraw = (depositQuoteToken -  Number(depositQuoteToken) * Number(dfr)).toFixed(12)




        // const url = `${AUTOFILL_API}?afb=${askForBorrowAmount}`
        // const res = await axios.get(url)
        // const { depositCollateral, depositQuoteToken, repaycollateral, withdraw } = res?.data?.cal
        setQuoteTokenAmount(depositQuoteToken);
        setborrowAmount(askForBorrowAmount);
        setBorrowColletralAmount(depositCollateral);
        setRepayColletralAmount(repaycollateral);
        setRepayQuoteAmount(askForBorrowAmount);
        setWithdrawQuoteTokenAmount(withdraw);


        try {

          axios.post(borrow_upsert, {
            address: address,
            borrow_ask: askForBorrowAmount,
          })
          notifySuccess("borrow amount saved")
        } catch (error) {
          notifyError("borrow amount not saved")
          console.error("server error", error)
        }


      }



    } catch (error) {
      notifyError("AutoFill Failed.")
      console.error("AutoFill Failed.", error)
    }



  };


// const testCalculations =  () => {
//   const askForBorrowAmount = "0.000001"
//   const dfr = "0.000013698630136986"
//   const depositQuoteToken = (Number(askForBorrowAmount) + Number(askForBorrowAmount) * Number(dfr) ).toFixed(18)
//   const 

// }

  const start_epoch = async () => {
    try {
      const valueInWei = ethers.BigNumber.from(minutes);
      const tx = await contract.start_epoch(valueInWei, { gasLimit: 3000000 });
      await tx.wait();
      console.log("start epoch Tx Successful..", tx)
      notifySuccess("Start Epoch Tx Successful.")

      handleStart()
      getAllAcounting()
    } catch (error) {
      notifyError("Start Epoch Tx Failed.")
      console.log(error)
    }


  };

  const end_epoch = async () => {
    try {

      const tx = await contract.end_epoch({ gasLimit: 3000000 });
      await tx.wait();
      console.log("end epoch Tx Successful..", tx)
      notifySuccess("End Epoch Tx Successful.")
      getAllAcounting()
    } catch (error) {
      notifyError("End Epoch Tx Failed.")
    }


  };

  const withdrawQuoteToken = async () => {
    try {
      // const a = await convertFROMUSD(withdrawQuoteTokenAmount, "WETH")
      const tx = await contract.withdrawQuoteToken(ethers.utils.parseEther(`${withdrawQuoteTokenAmount}`), { gasLimit: 3000000 });
      await tx.wait();
      console.log("withdrawQuoteToken Tx Successful..", tx)
      notifySuccess("Withdraw Quote Token Tx Successful.")
      getAllAcounting()
    } catch (error) {
      notifyError("Withdraw Quote Token Tx Failed.")
    }

  };



  const approveBorrow = async () => {
    try {
      const approvTx = await WSTContract.approve(LendvestAjnaAddress, ethers.utils.parseEther(`${borrowColletralAmount}`), { gasLimit: 3000000 })
      await approvTx.wait();
      console.log("Approve Tx Successful..", approvTx)
      notifySuccess("Approve Tx Successful.")
    } catch (error) {
      notifyError("Approve Tx Failed.")
    }


  };



  const borrow = async () => {

    try {
      const tx = await contract.borrow(ethers.utils.parseEther(`${borrowAmount}`), ethers.utils.parseEther(`${borrowColletralAmount}`), { gasLimit: 3000000 });
      await tx.wait();
      console.log("Borrow Tx Successful..", tx)
      notifySuccess("Borrow Tx Successful.")
      getAllAcounting()
    } catch (error) {
      notifyError("Borrow Tx Failed.")
    }

  };




  const repay = async () => {
    console.log("repayColletralAmountrepayColletralAmount", repayColletralAmount)
    console.log(ethers.utils.parseEther(`${repayColletralAmount}`))
    try {
      const tx = await contract.repay(ethers.utils.parseEther(`${repayColletralAmount}`), { gasLimit: 3000000 });
      // const tx = await contract.repayTest(ethers.utils.parseEther(`${0.000001}`),ethers.utils.parseEther(`${repayColletralAmount}`) ,{ gasLimit: 3000000 });
      
      await tx.wait();
      console.log("Repay Tx Successful..", tx)
      getAllAcounting()
      notifySuccess("Repay Tx Successful.")
    } catch (error) {
      console.log(error);
      notifyError("Repay Tx Failed.")
    }
}



  const approveDepositColletralForLiquidation = async () => {
    try {
      const approvTx = await WSTContract.approve(LendvestAjnaAddress, ethers.utils.parseEther(`${depositColletralAmount}`), { gasLimit: 3000000 })
      await approvTx.wait();
      console.log("Approve Tx Successful..", approvTx)
      notifySuccess("Approve Tx Successful.")
    } catch (error) {
      notifyError("Approve Tx Failed.")
    }
};


  const depositColletralForLiquidation = async () => {
    try {
      const tx = await contract.depositCollateralForLiquidation(ethers.utils.parseEther(`${depositColletralAmount}`), { gasLimit: 3000000 });
      await tx.wait();
      console.log("depositColletralForLiquidation Tx Successful..", tx)
      getAllAcounting()
      notifySuccess("Deposit Collateral Tx Successful.")
    } catch (error) {
      notifyError("Deposit Collateral Tx Failed.")
    }
  };

  const withdrawColletralofLiquidation = async () => {

    // const b = await convertFROMUSD(withdrawColletralAmount, "WSTETH")
    try {
      const tx = await contract.withdrawCollateralForLiquidation(ethers.utils.parseEther(`${withdrawColletralAmount}`), { gasLimit: 3000000 });
      await tx.wait();
      console.log("withdrawColletralofLiquidation Tx Successful..", tx)
      getAllAcounting()
      notifySuccess("Withdraw Collateral Tx Successful.")
    } catch (error) {
      notifyError("Withdraw Collateral Tx Failed.")
    }

  };


  const balanceColletralRatio = async () => {
    const tx = await contract.balanceColletralRatio(borrowerAddressForAvoidLiquidation, { gasLimit: 3000000 });
    await tx.wait();
    console.log("balanceColletralRatio Tx Successful..", tx)
    getAllAcounting()

  };


  const getQuoteTokenAddress = async () => {
    console.log("Hello QuoteToken Address ")
    const address = await contract.getQuoteTokenAddress();
    setQuoteTokenAddress(address)

  };


  const getCollateralAddress = async () => {
    console.log("Hello Colletral Address")
    const address = await contract.getCollateralAddress();
    setCollateralAddress(address)
  };


   const getPoolAddress = async () => {
    const address = await contract.getPoolAddress();
    setPoolAddress(address);
    window.open(`https://ajnafi.com/polygon/pools/${address}`, '_blank').focus();

  };


  
  const gotoLendvestVault =  () => {
    window.open(`https://polygonscan.com/address/${LendvestAjnaAddress}#code`, '_blank').focus();

  };

  const getAmounts = async () => {
    setAmountWethToUSD(await convertToUSD("WETH"));
    setAmountWWSTethToUSD(await convertToUSD("WSTETH"));
  }
  const pollForUpdates = async () => {
    try {
    const response = await axios.get(UPDATES_API, { timeout: 30000 }); // 30 seconds timeout
    if (response.status === 200) {
      setTotalBorrowAsk(response.data.totalBorrow)
      setTotalBorrowAskUSD(convertInUSD(response.data.totalBorrow, amountWethToUSD))
      setTotalLend(response.data.totalLend)
      setTotalLendUSD(convertInUSD(response.data.totalLend, amountWethToUSD))
      setLenders(response?.data?.lenders)
    
    } else if (response.status === 204) {
      // No content, indicating no updates
      console.log('No updates available.');
    }
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      // Handle timeout error
      console.error('Polling request timed out:', err);
    } else {
      // Handle other errors
      console.error('Error fetching updates:', err);
    }
 
  }
};


  useEffect(() => {
    if (address && amountWethToUSD && amountWSTethToUSD) {
      getAllAcounting();
    }
    address && getAllAcounting();
  }, [address])

  useEffect(() => {
    getAmounts()
    const interval = setInterval(pollForUpdates, 5000); // Poll every 5 seconds
    pollForUpdates()
    return () => clearInterval(interval); // Clean up on unmount

  }, []);

  return (
    <AppLayout setWallet={setAddress} showHeader={false} subPage={true} wallet={address}>
      <nav className=" px-2 sm:px-4 py-2.5 dark:bg-gray-900  bg-black w-full z-20 top-0  left-0 ">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <a href="#" className="flex items-center">
            <span className="text-white self-center text-xl font-semibold whitespace-nowrap dark:text-white"><img src="/logo.png" /></span>
          </a>
          <div className="flex md:order-2">


            <>
              <Button onClick={() => navigate('/')} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                Home
              </Button>
              <Button onClick={connectWallet} className="bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] w-48 text-white text-center flex justify-center items-center rounded-lg">
                {address ? `${address.slice(0, 6)}...${address.slice(-4)}` : 'Connect Wallet'}
              </Button>
            </>
          </div>
          <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">

          </div>
        </div>
      </nav >
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <div className="mt-20">
          <Space
            direction="vertical"
            size={50}
            style={{
              display: 'flex',
            }}
          >
            {/* <TermsAndTimer /> */}

          </Space>
        </div>

        <div className="flex justify-around items-start mt-4">
          <div className="w-[25%]">
            <ButtonWithText isApprove={true} approveFunc={ApproveLendQuoteToken} onClick={LendQuoteToken} text1={"Lend"} text2={"LendQuoteToken"} crypto="WETH" amountInUSD={amountWethToUSD} label="amount" value={quoteTokenAmount} setFunc={setQuoteTokenAmount} />

          </div>
          <div className="w-[25%]">
            <ButtonWithText isApprove={false} onClick={start_epoch} text1={"Begin Term Loan"} text2="start_epoch" label="no. of minutes" value={minutes} setFunc={setMinutes} />
          </div>
        </div>


        <div className="flex justify-around items-start mt-4">
          <div className="w-[25%]">
            <ButtonWithText isApprove={false} onClick={AskForBorrow} text1={"Ask For Borrow"} crypto="WETH" amountInUSD={amountWethToUSD} label="amount" value={askForBorrowAmount} setFunc={setAskForBorrowAmount} />
          </div>
        </div>



        <div className="flex justify-around items-start mt-4">
          <div className="w-[25%]">
            <ButtonWithTwoText isApprove={true} apprveFunc={approveBorrow} onClick={borrow} text1={"Borrow WETH"} text2={"borrow"} label1="collateral amount" label2="borrow amount" amountWethToUSD={amountWethToUSD} amountWSTethToUSD={amountWSTethToUSD} value1={borrowColletralAmount} setFunc1={setBorrowColletralAmount} value2={borrowAmount} setFunc2={setborrowAmount} />

          </div>
          {/* <div className="w-[25%]">
            <ButtonWithTwoText onClick={repay} text1={"Repay"} text2={""} label1="collateral amount" label2="repay amount" amountWethToUSD={amountWethToUSD} amountWSTethToUSD={amountWSTethToUSD} value1={repayColletralAmount} setFunc1={setRepayColletralAmount} value2={repayQuoteAmount} setFunc2={setRepayQuoteAmount} />

          </div> */}

          <div className="w-[25%]">
            <ButtonWithText isApprove={false} onClick={repay} text1={"Repay"} text2={""} crypto="WSTETH" amountInUSD={amountWSTethToUSD} label="amount" value={repayColletralAmount} setFunc={setRepayColletralAmount} />

          </div>
        </div>


        <div className="flex justify-around items-start mt-4">
          <div className="w-[25%]">
            <ButtonWithText isApprove={true} approveFunc={approveDepositColletralForLiquidation} onClick={depositColletralForLiquidation} text1="Deposit Collateral" crypto="WSTETH" amountInUSD={amountWSTethToUSD} text2="depositCollateralForLiquidation" label="amount" value={depositColletralAmount} setFunc={setDepositColletralAmount} />

          </div>
          <div className="w-[25%]">
            <ButtonWithText isApprove={false} onClick={withdrawQuoteToken} text1={"Withdraw WETH"} text2={"withdrawQuoteToken"} crypto="WETH" amountInUSD={amountWethToUSD} label="amount" value={withdrawQuoteTokenAmount} setFunc={setWithdrawQuoteTokenAmount} />
          </div>
        </div>

        <div className="flex justify-around items-start mt-4">
          <div className="w-[25%]">
            <ButtonWithText isApprove={false} onClick={withdrawColletralofLiquidation} text1={"Withdraw Collateral"} crypto="WSTETH" amountInUSD={amountWSTethToUSD} text2="withdrawCollateralofLiquidation" label="amount" value={withdrawColletralAmount} setFunc={setWithdrawColletralAmount} />

          </div>
          <div className="w-[25%]"></div>
        </div>

        <div className="flex justify-center items-stretch mt-5 flex-wrap">
          <div className="w-[87%] flex justify-around flex-wrap gap-5">
            <div className="w-[35%]">
              <Card hoverable>
                <div className="flex justify-center flex-col items-center">

                  <Button onClick={end_epoch} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                    End Term Loan
                  </Button>
                </div>
              </Card>
            </div>
            <div className="w-[35%]">
              <Card hoverable>
                <div className="flex justify-center flex-col items-center">

                  <Button onClick={getQuoteTokenAddress} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                    Add WETH Address to Wallet
                  </Button>
                  {quoteTokenAddress && (
                    <Text style={{ display: 'block', marginTop: '8px' }}>
                      Quote Token Address: {quoteTokenAddress}
                    </Text>
                  )}

                </div>
              </Card>
            </div>
            <div className="w-[35%]">
              <Card hoverable>
                <div className="flex justify-center flex-col items-center">

                  <Button onClick={getQuoteTokenAddress} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                    Add WSTETH Address to Wallet
                  </Button>
                  {collateralAddress && (
                    <Text style={{ display: 'block', marginTop: '8px' }}>
                      Collateral Address: {collateralAddress}
                    </Text>
                  )}

                </div>
              </Card>
            </div>
            <div className="w-[35%]">
              <Card hoverable>
                <div className="flex justify-center flex-col items-center">

                  <Button onClick={getPoolAddress} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                    Go to Lendvest's Ajna Pool
                  </Button>
                  {poolAddress && (
                    <Text style={{ display: 'block', marginTop: '8px' }}>
                      Pool Address: {poolAddress}
                    </Text>
                  )}
                </div>
              </Card>
            </div>


            <div className="w-[35%]">
              <Card hoverable>
                <div className="flex justify-center flex-col items-center">

                  <Button onClick={gotoLendvestVault} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                    Go to Lendvest's Ajna Vault
                  </Button>
     
                </div>
              </Card>
            </div>


          </div>
        </div>
        <div className='flex justify-center items-center'>
          <Card className='mt-5 w-[75%]' hoverable>
            <h2 className='mb-2 text-center text-4xl w-full text-white'>Markets</h2>
            <div className='flex justify-between items-center gap-4 flex-wrap'>
              <div className="w-[40%]">
                <Card hoverable>
                  <div className="flex justify-center flex-col items-center">

                    <Button onClick={() => { window.open('https://app.aave.com/markets/?marketName=proto_mainnet', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                      Aave V2
                    </Button>
                    {poolAddress && (
                      <Text style={{ display: 'block', marginTop: '8px' }}>
                        Pool Address: {poolAddress}
                      </Text>
                    )}
                  </div>
                </Card>
              </div>
              <div className="w-[40%]">
                <Card hoverable>
                  <div className="flex justify-center flex-col items-center">

                    <Button onClick={() => { window.open('https://app.aave.com/markets/?marketName=proto_mainnet_v3', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                      Aave V3
                    </Button>
                    {poolAddress && (
                      <Text style={{ display: 'block', marginTop: '8px' }}>
                        Pool Address: {poolAddress}
                      </Text>
                    )}
                  </div>
                </Card>
              </div>
              <div className="w-[40%]">
                <Card hoverable>
                  <div className="flex justify-center flex-col items-center">

                    <Button onClick={() => { window.open('https://analytics.morpho.org/', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                      Morpho-Aave V2
                    </Button>
                    {poolAddress && (
                      <Text style={{ display: 'block', marginTop: '8px' }}>
                        Pool Address: {poolAddress}
                      </Text>
                    )}
                  </div>
                </Card>
              </div>
              <div className="w-[40%]">
                <Card hoverable>
                  <div className="flex justify-center flex-col items-center">

                    <Button onClick={() => { window.open('https://analytics.morpho.org', '_blank').focus(); }} className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                      Morpho-Aave V3
                    </Button>
                    {poolAddress && (
                      <Text style={{ display: 'block', marginTop: '8px' }}>
                        Pool Address: {poolAddress}
                      </Text>
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </Card>
        </div>

        <div className="w-full flex justify-center mt-5">
          <div className="w-[75%] flex justify-between flex-wrap items-center gap-4">
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Borrower's Interest Owed in WETH</div>
                      <div className="mt-2 text-2xl">WETH {InterestPaidForCollateralActual}</div>
                      <div className="mt-2 text-2xl">US ${InterestPaidForCollateral}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%]">



            <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Pool Information</div>
                      <div className="mt-2 text-2xl">Deposit Size {depositSize} WETH</div>
                      <div className="mt-2 text-2xl">Collateral Pledge {collateralPledge} WSTETH</div>
                      <div className="mt-2 text-2xl">Cuurent Debt {curentDebt} WETH</div>

                    </div>
                  </div>
                </span>
              </Card>

              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >WETH In Lendvest's Contract</div>
                      <div className="mt-2 text-2xl">WETH {totalAmountOfQuoteTokenActual}</div>
                      <div className="mt-2 text-2xl">US ${totalAmountOfQuoteToken}</div>
                    </div>
                  </div>
                </span>
              </Card>


              <div className="w-[40%]">
                <Card hoverable >
                  <span>
                    <div>
                      <div className="flex justify-center items-center flex-col">
                        <div className="text-[1.3rem]" >Total Borrow Ask</div>
                        <div className="mt-2 text-2xl">WETH {totalBorrowAsk}</div>
                        <div className="mt-2 text-2xl">US ${totalBorrowAskUSD}</div>
                      </div>

                      <div className="flex justify-center items-center flex-col">
                        <div className="text-[1.3rem]" >Total Lend</div>
                        <div className="mt-2 text-2xl">WETH {totalLend}</div>
                        <div className="mt-2 text-2xl">US ${totalLendUSD}</div>
                        <div className="mt-2 text-2xl">Lenders Submit {calculateLendSubmission(totalLend, totalBorrowAsk)}% of Max Lend</div>
                        <div className="mt-2 text-2xl">Remaining Utilization {calculateLendSubmission(totalBorrowAsk - totalLend, totalBorrowAsk)}% of Max Lend</div>

                      </div>
                    </div>

                    {/* {
                      lenders?.map((lender, index) => {
                        return (
                          <div className="flex justify-center items-center flex-col">
                            <div className="text-[1.3rem]" >User : {lender?.address && `${lender?.address.slice(0, 6)}...${lender?.address.slice(-4)}`}</div>
                            <div className="mt-2 text-2xl">WETH {lender?.lend_amount}</div>
                            <div className="mt-2 text-2xl">US ${convertInUSD(lender?.lend_amount, amountWethToUSD)}</div>
                            <div className="mt-2 text-2xl">Submit {calculateLendSubmission(lender?.lend_amount, totalBorrowAsk)}% of Max Lend</div>

                          </div>

                        )
                      }
                      )} */}

                  </span>
                </Card>
              </div>
            </div>
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >WSTETH In Lendvest's Contract</div>
                      <div className="mt-2 text-2xl">WSTETH {totalAmountOfQuoteTokenActual}</div>
                      <div className="mt-2 text-2xl">US ${totalAmountOfQuoteToken}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Amount Lent on Aave</div>
                      <div className="mt-2 text-2xl">WETH {totalAmountOfBorrowedActual}</div>
                      <div className="mt-2 text-2xl">US ${totalAmountOfBorrowed}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Cross Collateralization in Reserve</div>
                      <div className="mt-2 text-2xl">WETH {totalAmountOnAaveActual}</div>
                      <div className="mt-2 text-2xl">US ${totalAmountOnAave}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Lender's Amount in WETH</div>
                      <div className="mt-2 text-2xl">WETH {supplierToAmountActual}</div>
                      <div className="mt-2 text-2xl">US ${supplierToAmount}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Lender's Interest Earned in WETH</div>
                      <div className="mt-2 text-2xl">WETH {interestEarnedByUserActual}</div>
                      <div className="mt-2 text-2xl">US ${interestEarnedByUser}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%]">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem]" >Borrower's Collateral in WSTETH</div>
                      <div className="mt-2 text-2xl">WSTETH {borrowerToCollateralAmountActual}</div>
                      <div className="mt-2 text-2xl">US ${borrowerToCollateralAmount}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%] last-cards">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem] text-center" >Collateral Lender's Amount Deposited in WSTETH</div>
                      <div className="mt-2 text-2xl">WSTETH 0.000000</div>
                      <div className="mt-2 text-2xl">US $0.00</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
            <div className="w-[40%] last-cards">
              <Card hoverable >
                <span>
                  <div>
                    <div className="flex justify-center items-center flex-col">
                      <div className="text-[1.3rem] text-center" >Collateral Lender's Amount Lent  in WSTETH</div>
                      <div className="mt-2 text-2xl">WSTETH {collateralProviderToCrossColletralAmountActual}</div>
                      <div className="mt-2 text-2xl">US ${collateralProviderToCrossColletralAmount}</div>
                    </div>
                  </div>
                </span>
              </Card>
            </div>
          </div>

        </div>


        <Row>

          <Col>

            <Timer className="d-flex align-items-center" duration={duration} isActive={isActive} setDuration={setDuration} deadline={deadline} handleReset={handleReset} />
          </Col>


          <div style={{ position: 'fixed', top: '1.6%', left: '50%' }}>
            <Text style={{ fontSize: '17px', fontWeight: '600' }}>
              Ongoing Term?: {epochStatus}
            </Text>
          </div>



        </Row>

        <Divider orientation="left" />
        <Button onClick={() => convertFROMUSD(1, "WSTETH")} type="primary" >
          USD API Test
        </Button>
        <Button onClick={() => test()} type="primary" >
          Test
        </Button>
        {/* <Button onClick={() => notifySuccess("Hello World")} type="primary" >Notify Success</Button>
      <Button onClick={() => notifyError("Hello World")} type="primary" >Notify Errr</Button> */}

      </div>
    </AppLayout >
  );
};


export default Home;
