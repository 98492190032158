
import "./index.css"
import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { Card, Row, Col, Space, Button, Input } from "antd";
import Loader from "../../Components/Loader";
import AppLayout from "../../Components/Layout";
import GaugeChart from "../../Components/Charts/GaugeChart";
import { useAccount, useContract, useSigner, useContractEvent, useContractWrite } from "wagmi";
import { abi } from "../../constants/abis";
import SearchSelect from "../../Components/SearchSelect";
import { QuestionCircleOutlined } from "@ant-design/icons";
import TermsAndTimer from "../../Components/TermsAndTimer";
// import Footer from "../../Components/Footer";



function CollateralLender() {


    const [score, setScore] = useState(600);
    const [TxCount, SetTxCount] = useState(0)
    const [TxVolume, SetTxVolume] = useState(0)
    const [OutstandingLoans, SetOutstandingLoans] = useState(0)
    const [TotalBalance, SetTotalBalance] = useState(0)
    const [LoanHistory, SetLoanHistory] = useState([])
    const [LoanHistoryTable, SetLoanHistoryTable] = useState([])
    const [wallet, setWallet] = useState("")
    const [requestID, setRequestID] = useState("")

    const [loader, setLoader] = useState(false)

    const walletAddress = useRef("");


    const { address, isConnected, isDisconnected } = useAccount()
    const contractAddress = "0xB9bF4715142483A82C855312C384F1dB431bbb8E"
    const provider = new ethers.providers.WebSocketProvider("wss://capable-thrilling-daylight.ethereum-sepolia.quiknode.pro/0578dcce030c131658f0e28d9e4574bc4ea3ae3f/")

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: contractAddress,
        abi: abi,
        functionName: 'requestScore',
        args: [wallet]
    })








    const getScore = async () => {

        if (wallet) {
            setLoader(true)
            console.log('wller', wallet);
            localStorage.setItem('wal', wallet)
            write()

        }

    }


    useEffect(() => {

        if (isConnected) {

            var reqId = '';
            // var walletAdd = 
            const contract = new ethers.Contract(contractAddress, abi, provider)
            contract.on("ChainlinkRequested", (requestId) => {
                // console.log(walletAddress.current);
                // setRequestID(requestId);
                reqId = requestId;

                console.log(requestId)

            })

            contract.on("RequestScore", (requestId, credit_Score, userAddress) => {

                console.log("Ayaaaaaaaaaaaa")
                console.log('token', reqId);
                console.log(localStorage.getItem('wal'))
                console.log(userAddress)
                console.log(requestId === reqId)
                console.log(localStorage.getItem('wal') === userAddress)
                if (requestId === reqId && localStorage.getItem('wal') === userAddress) {

                    const creditScore = ethers.utils.formatEther(credit_Score) * 10 ** 18
                    if (creditScore === "0.0") {
                        // open modal
                    }
                    else {
                        console.log(creditScore)
                        setScore(creditScore)
                    }
                    setLoader(false)
                }

                console.log(requestId)
                console.log(credit_Score)
                console.log(userAddress)
            })

        }
    }, [isConnected])



    return (
        <AppLayout setWallet={setWallet} showHeader={true} subPage={true} wallet={wallet}>

            {loader && <div className="fixed bg-gray-400/80 w-full h-full top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                <Loader />
            </div>
            }

            <div className="mt-20 min-h-[77vh] p-5">
                <Space
                    direction="vertical"
                    size={50}
                    style={{
                        display: 'flex',
                    }}
                >

                    <TermsAndTimer />

                    <div className="flex justify-around items-start">
                        <div className="w-[25%]">
                            <Card hoverable>
                                <div className="flex justify-between items-center">

                                    <h2 className="text-xl">
                                        WSTETH
                                    </h2>
                                    <Input className="w-[80%] ml-2" type="number" />
                                </div>
                                <div className="flex justify-center flex-col items-center">

                                    <Button className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                        Withdraw Collateral
                                    </Button>

                                    
                                </div>
                            </Card>
                        </div>
                        <div className="w-[35%]">
                            <Card hoverable >
                                <span>
                                    <div>
                                        <div className="flex justify-center items-center flex-col">
                                            <div className="text-[1.3rem]" >Cross Collateralization in Reserve</div>
                                            <div className="mt-2 text-2xl">WETH 0.000000</div>
                                            <div className="mt-2 text-2xl">US $0.00</div>
                                        </div>
                                    </div>
                                </span>
                            </Card>
                        </div>
                    </div>

                    <div className="flex justify-around items-stretch">
                        <div className="w-[25%]">
                            <Card hoverable>
                                <div className="flex justify-between items-center">

                                    <h2 className="text-xl">
                                        WSTETH
                                    </h2>
                                    <Input className="w-[80%] ml-2" type="number" />
                                </div>


                                <div className="flex justify-center flex-col items-center">

                                    <Button className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                        Deposit Collatera
                                    </Button>
                                    <div className="w-full p-2 text-center card-gradient">
                                        <p>WSTETH In Lendvest's Contract</p>
                                        <div className="flex justify-center items-center">

                                            <label>WSTETH:</label>
                                            <span className="ml-2">0.000000</span>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <label>US:</label>
                                            <span className="ml-2">$0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="w-[35%]">
                            <Card hoverable >
                                <span>
                                    <div>
                                        <div className="flex justify-center items-center flex-col">
                                            <div className="text-[1.3rem] text-center" >Collateral Lender's Amount Deposited in WSTETH</div>
                                            <div className="mt-2 text-2xl">WSTETH 0.000000</div>
                                            <div className="mt-2 text-2xl">US $0.00</div>
                                        </div>
                                    </div>
                                </span>
                            </Card>
                        </div>
                    </div>


                    <div className="flex justify-around items-stretch">
                        <div className="w-[25%]">
                            {/* <Card hoverable>
                                <div className="flex justify-between items-center">

                                    <h2 className="text-xl">
                                        WSTETH
                                    </h2>
                                    <Input className="w-[80%] ml-2" type="number" />
                                </div>


                                <div className="flex justify-center flex-col items-center">

                                    <Button className="w-full bg-[#3800ff] m-2 text-lg h-12 font-bold hover:bg-[#191a1c98] text-white text-center flex justify-center items-center rounded-lg">
                                        Deposit Collatera
                                    </Button>
                                    <div className="w-full p-2 text-center card-gradient">
                                        <p>WSTETH In Lendvest's Contract</p>
                                        <p>Total Amount Of Collateral</p>
                                        <div className="flex justify-center items-center">

                                            <label>WSTETH:</label>
                                            <span className="ml-2">0.00</span>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <label>US:</label>
                                            <span className="ml-2">$0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </Card> */}
                        </div>
                        <div className="w-[35%]">
                            <Card hoverable >
                                <span>
                                    <div>
                                        <div className="flex justify-center items-center flex-col">
                                            <div className="text-[1.3rem]" >Collateral Lender's Amount Lent  in WSTETH</div>
                                            <div className="mt-2 text-2xl">WSTETH 0.000000</div>
                                            <div className="mt-2 text-2xl">US $0.00</div>
                                        </div>
                                    </div>
                                </span>
                            </Card>
                        </div>
                    </div>
                </Space>

            </div>



        </AppLayout >
    );
}

export default CollateralLender;
