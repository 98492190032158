import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Statistic } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const { Countdown } = Statistic;

const Timer = ({duration, isActive, setDuration, deadline,handleReset}) => {
  

  

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setDuration((duration) => {
          if (duration > 0) {
            return duration - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
    } else if (!isActive && duration !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);

  }, [isActive]);



  return (
    // <Row gutter={16}>
    //   <Col span={12}>
    <div style={{position: 'fixed', top: '10px', left: '40%', color: 'white'}}>
      <Countdown
      className='text-white'
        title="Countdown Timer"
        value={deadline}
        format="mm:ss"
        onFinish={handleReset}
      />
    </div>
    //   </Col>
      
    // </Row>
  );
};

export default Timer;
